html::-webkit-scrollbar {
  display: none;
}

html {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

main {
  min-height: 80vh;
}

header {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.rating span {
  margin: 0.1rem;
}

.rating svg {
  color: #f8e825;
}

.about {
  padding: 10px;
}

.rating-text {
  font-size: 0.8rem;
  font-weight: 600;
  padding-left: 0.5rem;
}

.product-title {
  height: 2.5em; /* Set a fixed height */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Add ellipsis for long text */
  white-space: nowrap; /* Prevent wrapping */
}

table td,
table th {
  text-align: center;
}

.review {
  margin-top: 30px;
}

.review h2 {
  font-size: 24px;
  background: #f4f4f4;
  padding: 10px;
  border: 1px solid #ddd;
}

.review button {
  margin-top: 10px;
}

.logo {
  max-height: 5rem;
  padding-right: 10px;
}

.image-container {
  position: relative;
  width: 100%;
  padding-top: 75%;
  overflow: hidden;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.c-image {
  height: 40vh;
  width: 100%;
}

.text {
  color: white;
}

.links {
  color: white;
  padding: 10px;
}

.links:hover {
  color: white;
}

.links:active,
.links:focus {
  color: white;
  font-weight: bold;
}

.home-screen {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: auto;
  background-image: url("../home-page.png"); /* Or an external URL */
  background-size: cover; /* Adjusts the size of the background */
  background-position: center; /* Centers the image */
  background-attachment: fixed;
  width: 100vw; /* Full width of the viewport */
  height: 100vh; /* Full height of the viewport */
}

.home-screen-text {
  color: white;
  font-size: 1.2rem;
  padding-top: 5rem;
  font-weight: 100;
}

.home-screen-footer {
  color: white;
}

.home-screen-links {
  color: white;
  font-weight: 100 !important;
  font-size: 20px;
  width: 160px;
  margin: 10px !important;
}

@media (min-width: 992px) {
  .nav-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .nav-container > div {
    display: flex;
    align-items: center;
  }

  .nav-page-links {
    padding-left: 20px;
  }

  .links {
    padding-left: 10px !important;
  }
}

@media (max-width: 991px) {

  .nav-page-links {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .links {
    padding: 0 !important;
    padding-top: 5px !important;
  }
  .nav-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-container > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 !important;
  }
}